/** @format */

import React from "react";
import Navbar from "../components/Navbar";
// import {Link} from "react-router-dom"
export default function Home() {
  return (
    <div>
      {/* <h1>This is the home page</h1>
      <Link to="printemps">We are offering the following services</Link>
      <Link to="été">You can contact us by submitting form</Link> */}
      <Navbar />
      <p>Bienvenue, </p>
    </div>
  );
}
