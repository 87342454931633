/** @format */

import { v4 as uuidv4 } from "uuid";
// import Printemps from "./Printemps";

const dataSeason = [
  { 
    id: uuidv4(),
    title: "printemps",
    fruitsprintemps: [
      {
        fruit: "cerise",
        icon: "cerise.png",
      },
      {
        fruit: "citron",
        icon: "citron.png",
      },
      {
        fruit: "fraise",
        icon: "fraise.png",
      },
      {
        fruit: "framboise",
        icon: "framboise.png",
      },
      {
        fruit: "kiwi",
        icon: "kiwi.png",
      },
      {
        fruit: "orange",
        icon: "orange.png",
      },
      {
        fruit: "pamplemousse",
        icon: "pamplemousse.png",
      },
      {
        fruit: "pomme",
        icon: "pomme.png",
      },
      {
        fruit: "poire",
        icon: "poire.png",
      },
      {
        fruit: "rhubarbe",
        icon: "rhubarbe.png",
      },
    ],
    legumesprintemps: [
      {
        legume: "ail nouveau",
        icon: "ail.png",
      },
      {
        legume: "asperge",
        icon: "asperges.png",
      },
      {
        legume: "aubergine",
        icon: "aubergine.png",
      },
      {
        legume: "betterave",
        icon: "betterave.png",
      },
      {
        legume: "blette",
        icon: "blette.png",
      },
      {
        legume: "carotte",
        icon: "carottes.png",
      },
      {
        legume: "céleri",
        icon: "celeri.png",
      },
      {
        legume: "chou-fleur",
        icon: "chou-fleur.png",
      },
      {
        legume: "choux de Bruxelles",
        icon: "chouxbruxelles.png",
      },
      {
        legume: "concombre",
        icon: "concombre.png",
      },
      {
        legume: "courgette",
        icon: "courgette.png",
      },
      {
        legume: "endive",
        icon: "endive.png",
      },
      {
        legume: "épinards",
        icon: "epinards.png",
      },
      {
        legume: "salade frisée",
        icon: "salade.png",
      },
      {
        legume: "fenouil",
        icon: "fenouil.png",
      },
      {
        legume: "salade laitue",
        icon: "laitue.png",
      },
      {
        legume: "navet",
        icon: "navet.png",
      },
      {
        legume: "oignon",
        icon: "oignon.png",
      },
      {
        legume: "petits pois",
        icon: "pois.png",
      },
      {
        legume: "pomme de terre",
        icon: "pdt.png",
      },
      {
        legume: "poireau",
        icon: "poireau.png",
      },
      {
        legume: "radis",
        icon: "radis.png",
      },
    ],
  },
  {
    id: uuidv4(),
    title: "été",
    fruitsete: [
      {
        fruit: "abricot",
        icon: "abricot.png",
      },
      {
        fruit: "cassis",
        icon: "cassis.png",
      },
      {
        fruit: "cerise",
        icon: "cerise.png",
      },
      {
        fruit: "figue",
        icon: "figues.png",
      },
      {
        fruit: "fraise",
        icon: "fraise.png",
      },
      {
        fruit: "framboise",
        icon: "framboise.png",
      },
      {
        fruit: "groseille",
        icon: "groseille.png",
      },
      {
        fruit: "melon",
        icon: "melon.png",
      },
      {
        fruit: "mirabelle",
        icon: "mirabelle.png",
      },
      {
        fruit: "myrtille",
        icon: "myrtilles.png",
      },
      {
        fruit: "nectarine",
        icon: "nectarine.png",
      },
      {
        fruit: "pastèque",
        icon: "pasteque.png",
      },
      {
        fruit: "pêche",
        icon: "peche.png",
      },
      {
        fruit: "poire",
        icon: "poire.png",
      },
      {
        fruit: "pomme",
        icon: "pomme.png",
      },
      {
        fruit: "prune",
        icon: "prune.png",
      },
      {
        fruit: "pruneau",
        icon: "pruneau.png",
      },
    ],

    legumesete: [
      {
        legume: "ail",
        icon: "ail.png",
      },
      {
        legume: "artichaut",
        icon: "artichaut.png",
      },
      {
        legume: "asperge",
        icon: "asperges.png",
      },
      {
        legume: "aubergine",
        icon: "aubergine.png",
      },
      {
        legume: "betterave",
        icon: "betterave.png",
      },
      {
        legume: "blette",
        icon: "blette.png",
      },
      {
        legume: "carotte",
        icon: "carottes.png",
      },
      {
        legume: "céleri",
        icon: "celeri.png",
      },
      {
        legume: "chou-blanc",
        icon: "chou.png",
      },
      {
        legume: "chou-fleur",
        icon: "chou-fleur.png",
      },
      {
        legume: "concombre",
        icon: "concombre.png",
      },
      {
        legume: "courgette",
        icon: "courgette.png",
      },
      {
        legume: "épinards",
        icon: "epinards.png",
      },
      {
        legume: "fenouil",
        icon: "fenouil.png",
      },
      {
        legume: "haricot",
        icon: "haricot.png",
      },
      {
        legume: "salade laitue",
        icon: "laitue.png",
      },
      {
        legume: "maïs",
        icon: "mais.png",
      },
      {
        legume: "navet",
        icon: "navet.png",
      },
      {
        legume: "oignon",
        icon: "oignon.png",
      },
      {
        legume: "petits pois",
        icon: "pois.png",
      },
      {
        legume: "pomme de terre",
        icon: "pdt.png",
      },
      {
        legume: "poireau",
        icon: "poireau.png",
      },
      {
        legume: "poivron",
        icon: "poivron.png",
      },
      {
        legume: "radis",
        icon: "radis.png",
      },
      {
        legume: "tomate",
        icon: "tomate.png",
      },
    ],
  },
];

export default dataSeason;

// {
//     id: uuidv4(),
//     title: "automne",
//     fruits: ["attente"],
//     legumes: ["attente"],
//   },
//   {
//     id: uuidv4(),
//     title: "hiver",
//     fruits: ["attente"],
//     legumes: ["attente"],
//   },
