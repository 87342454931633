/** @format */

import React from "react";
import Navbar from "../components/Navbar";
import styled from "styled-components";
import dataSeason from "./dataSeason";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer";
// import PropTypes from "prop-types";
// import CardVegetable from "../components/CardVegetable";

export const FruitsStyled = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  margin-left: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  align-items: flex-start;
  text-align: right;
  color: black;
`;

export const LegumesStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  align-items: flex-start;
  text-align: left;
  color: black;
`;

//   @media (max-width: 450px) {
//     line-height: 20px;
//     font-size: 12px;
//   }

const TitleStyled = styled.h1`
  font-size: 25px;
  padding-top: 60px;
`;

const ImgStyled = styled.img`
  display: flex;
  width: 21px;
  height: 21px;
  z-index: 1;
  padding: 6px;
  position: absolute;
`;

// img {
//   object-fit: fill;
//   position: absolute;
//   width: 120vw;
//   height: 110vh;
//   color: white;
//   z-index: -1;
// }
const ElStyled = styled.li`
  padding-left: 50px;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 21px;
`;

const VegetableStyled = styled.div`
  display: flex;
  padding-top: 25px;
  justify-content: space-evenly;
  padding-bottom: 50px;
`;

const H3Styled = styled.h3`
  text-decoration: underline;
`;

const AStyled = styled.a`
  text-decoration: none;
`;
const UlStyled = styled.ul`
  line-height: 0px;
  color: black;
  margin: 0;
`;

//props + CardVegetable
// export default function Printemps({id, fruits, legumes, title})

export default function Printemps() {

  


  console.log("INFO DATA dataSeason", dataSeason);
  const params = useParams();
  const { id } = params;

  //pintemps
  const saisonPrintemps = dataSeason.find(
    (element) => element.fruitsprintemps.id === id
  );
  console.log("saison printemps find", saisonPrintemps);
  const fruitsPrintemps = saisonPrintemps.fruitsprintemps;
  const legumesPrintemps = saisonPrintemps.legumesprintemps;
  console.log("fruitsprintemps", fruitsPrintemps);
  console.log("legumesprintemps", legumesPrintemps);

    function handleClick(e) {
      e.preventDefault();
      console.log("Le lien cliqué est : ", e.target.innerText);
    }


  return (
    <div className="printempscontainer">
      <Navbar />
      <TitleStyled>
        <mark>Au printemps c'est 2 saison :</mark>
      </TitleStyled>
      <VegetableStyled>
        <LegumesStyled>
          <H3Styled>Les légumes : </H3Styled>
          {legumesPrintemps.map((element, index) => (
            <AStyled href=" " key={index} onClick={handleClick}>
              <UlStyled>
                <ImgStyled src={element.icon} alt="" />
                <ElStyled>{element.legume}</ElStyled>
              </UlStyled>
            </AStyled>
          ))}
        </LegumesStyled>
        <FruitsStyled>
          <H3Styled>Les fruits : </H3Styled>
          {fruitsPrintemps.map((element, index) => (
            <AStyled href=" " key={index}>
              <UlStyled>
                <ImgStyled src={element.icon} alt="" />
                <ElStyled> {element.fruit}</ElStyled>
              </UlStyled>
            </AStyled>
          ))}
        </FruitsStyled>
      </VegetableStyled>
      <Footer />
    </div>
  );
}




// //props + CardVegetable
// /* avec la CardVegetale on a toutes les données des 4 saisons
//   {dataSeason.map((element, index) => (
//         <CardVegetable
//         key={`${element.id}-${index}`}
//         fruits={element.fruits}
//         legumes={element.legumes}
//         title={element.title}
//         ></CardVegetable>
//         ) )}/*