/** @format */

import React from "react";
import Navbar from "../components/Navbar";
import styled from "styled-components";
import dataSeason from "./dataSeason";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer";
// import PropTypes from "prop-types";
// import CardVegetable from "../components/CardVegetable";

export const FruitsStyled = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  margin-left: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  align-items: flex-start;
  text-align: right;
  color: black;
`;

export const LegumesStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  align-items: flex-start;
  text-align: left;
  color: black;
`;

//   @media (max-width: 450px) {
//     line-height: 20px;
//     font-size: 12px;
//   }


const ImgStyled = styled.img`
  display: flex;
  width: 21px;
  height: 21px;
  z-index: 1;
  padding: 6px;
  position: absolute;
`;

// img {
//   object-fit: fill;
//   position: absolute;
//   width: 120vw;
//   height: 110vh;
//   color: white;
//   z-index: -1;
// }
const ElStyled = styled.div`
  padding-left: 50px;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 21px;
`;

const VegetableStyled = styled.div`
  display: flex;
  padding-top: 25px;
  justify-content: space-evenly;
  padding-bottom: 50px;
`;

const H3Styled = styled.h3`
  text-decoration: underline;
`;

const AStyled = styled.a`
  text-decoration: none;
`;
const UlStyled = styled.ul`
  line-height: 0px;
  color: black;
  margin: 0;
`;

const TitleStyled = styled.h1`
  font-size: 25px;
  padding-top: 60px;
`;

export default function Ete() {
  console.log("INFO DATA dataSeason", dataSeason);
//   const params = useParams();
//   const { id } = params;
  
//   //été

 //const saisonEte = dataSeason.find((element) => element.fruitsete.id === id);
  
 const saisonEte = dataSeason.find((element) => element.fruitsete);

// essais find, filter ...
 //  const essai = dataSeason.find((elt) => elt.fruitsete)
//  const essai2 = essai.fruitsete
//  console.log('essai', essai);
//  console.log('essai2', essai2);
// attention au id
  console.log("saisonEte", saisonEte);
  const fruitsEte = saisonEte.fruitsete;
  const legumesEte = saisonEte.legumesete

  console.log("fruitsete", fruitsEte);
  console.log("legumesete", legumesEte);

  return (
    <div className="etecontainer">
      <Navbar />
      <TitleStyled><mark>En été c'est de saison :</mark></TitleStyled>
      
      <VegetableStyled>
        <LegumesStyled>
          <H3Styled>Les légumes : </H3Styled>
          {legumesEte.map((element, index) => (
            <AStyled href=" " key={index}>
              <UlStyled>
                <ImgStyled src={element.icon} alt="" />
                <ElStyled> {element.legume}</ElStyled>
              </UlStyled>
            </AStyled>
          ))}
         </LegumesStyled>
         <FruitsStyled>
           <H3Styled>Les fruits : </H3Styled>
           {fruitsEte.map((element, index) => (
            <AStyled href=" " key={index}>
              <UlStyled>
                <ImgStyled src={element.icon} alt="" />
                <ElStyled> {element.fruit}</ElStyled>
              </UlStyled>
            </AStyled>
          ))}
        </FruitsStyled>
      </VegetableStyled>
      <Footer />
    </div>
  );
}
