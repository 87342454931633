import React from 'react'
import Navbar from '../components/Navbar'
import styled from 'styled-components';

const TitleStyled = styled.h1`
  font-size: 25px;
  padding-top: 60px;
`;

export default function Automne() {
  return (
    <div className="automnecontainer">
      <Navbar />
      <TitleStyled>A l'automne c'est de saison :</TitleStyled>
    </div>
  );
}
