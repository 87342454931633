import React from 'react'
import Navbar from '../components/Navbar'
import styled from 'styled-components';
import Footer from '../components/Footer';

const TitleStyled = styled.h1`
  font-size: 25px;
  padding-top: 60px;
`;

export default function Hiver() {
  return (
    <div className="hivercontainer">
      <Navbar />
      <TitleStyled>En hiver c'est de saison :</TitleStyled>
    <Footer/>
    </div>
  );
}
