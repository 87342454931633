/** @format */

import React from "react";
import "../index.css";

export default function Navbar() {
  return (
    <div>
      <nav className="navcontainer">
        <a href="/" className="navtitle">
          Les saisons
        </a>
        <ul className="navseasons">
          <li>
            <a href="/printemps" className="printemps">
              printemps
            </a>
            <a href="/été" className="ete">
              été
            </a>
            <a href="/automne" className="automne">
              automne
            </a>
            <a href="/hiver" className="hiver">
              hiver
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
